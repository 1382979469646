<template>
  <div style="height: 100%">
    <div id="container">
      <div class="about">
        <h1>zanelu.cn</h1>
        <template v-if="width > 1000">
          <p>
            This website has <strong>not built yet</strong>, just a parked<sup
              >(not)</sup
            >
            domain<sup>(in use)</sup>.
          </p>
        </template>
        <template v-else>
          <p>website has <strong>not built yet</strong>,</p>
          <p>just a parked domain.</p>
        </template>
        <p style="margin-top: 150px; font-size: 20px">
          <!-- <a href="https://blog.zanelu.cn">my blog</a> -->
          <router-link :to="blogPath">my blog</router-link>
          &nbsp;&NonBreakingSpace;
          <router-link :to="dartGamePath">a Darts Game</router-link>
        </p>
        <strong>
          <a href="https://beian.miit.gov.cn/#/Integrated/index"
            >浙ICP备2021010575号-2
          </a>
        </strong>
      </div>
    </div>
    <div id="three">
      <iframe
        src="shader.html"
        width="100%"
        height="100%"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dartGamePath: {
        path: 'dart',
        query: {},
      },
      blogPath: {
        path: 'blog',
        query: {},
      },
    };
  },
  computed: {
    width() {
      return window.screen.width;
    },
  },
  created() {
    if (Object.keys(this.$route.query).length > 0) {
      const { la, lo } = this.$route.query;
      this.dartGamePath.query = { la, lo };
    }
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
#container {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "TypoUbuntuRegular";
}
.about {
  margin: 0% 10%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.about p {
  font-size: 23px;
  text-decoration: none;
}
.about a {
  color: #fff;
}
.about sup {
  font-size: 0.5em;
}
#three {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
}
#footer {
  position: relative;
  /* top: -100px; */
  height: 50px;
}
</style>
