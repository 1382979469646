import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
// import GifGenerate from '../views/GifGenerate.vue';
import DartGame from '../views/DartGame.vue';
import HexoBlog from '../views/HexoBlog.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/dart',
    name: 'dart',
    component: DartGame,
  },
  {
    path: '/blog',
    name: 'blog',
    component: HexoBlog,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
